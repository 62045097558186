@import 'styles-variables';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/bootstrap';
@import '@fortawesome/fontawesome-free/css/all.css';
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,600,700,300); // Open Sans font
@import 'styles-cursors.scss';
@import 'styles-positions.scss';
@import 'dragula.scss';

body {
    color: $abq-font-color;
    font-size: 0.9rem;
}

.form-group label {
    text-align: right;
    font-weight: bold;
}

.form-control {
    font-size: 0.9rem;
}

.card {
    border-radius: 0;
    background-color: $abq-background;
    border-color: transparent;
}

.table {
    color: $abq-font-color;
}

.buttons-column {
    padding: 0.4rem;

    & button, & a {
        margin-right: 0.5rem;
        padding-top: 0;
        padding-bottom: 0;
    }
}

/* Image popovers. */

.popover-container {
    width: 200px;
    height: 200px;
}

.popover-image {
    height: 100%;
    min-height: 100px;
    max-height: 200px;
    width: 100%;
    min-width: 100px;
    max-width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* Overflows */
.overflow-y-auto {
    overflow-y: auto;
}

.overflow-auto {
    overflow: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.h-100 {
    height: 100%;
}

.font-size-1 {
    font-size: 1em;
}

.font-size-08 {
    font-size: .8em;
}

.font-size-09 {
    font-size: .9em;
}

.width-em8 {
    width: 8em;
}

.absolute-centered {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-all {
    pointer-events: all;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.border-rounded-button {
    border-radius: 1.25rem
}
