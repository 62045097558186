
.post-0 {
    top: 0 !important;
}

.posb-0 {
    bottom: 0 !important;
}

.posl-0 {
    left: 0 !important;
}

.posr-0 {
    right: 0 !important;
}

.post-1 {
    top: 0.25rem !important;
}

.posb-1 {
    bottom: 0.25rem !important;
}

.posl-1 {
    left: 0.25rem !important;
}

.posr-1 {
    right: 0.5rem !important;
}

.post-2 {
    top: 0.5rem !important;
}

.posb-2 {
    bottom: 0.5rem !important;
}

.posl-2 {
    left: 0.5rem !important;
}

.posr-2 {
    right: 0.5rem !important;
}
