//$grid-breakpoints: (
//    xs: 0,
//    sm: 576px,
//    md: 768px,
//    lg: 992px,
//    xl: 1200px
//);

//$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
//$font-size-base: 0.9rem;

$abq-green: #65AC1E;
$abq-lightgreen: lighten($abq-green, 40%);
$abq-orange: #f39500;
$abq-background: #F0F0F0;
$abq-background-dark: darken($abq-background, 10%);
$abq-background-light: lighten($abq-background, 10%);
$abq-background-hover: #f7f8fa;
$abq-background-tooltip: #34495e;
$abq-panel-border: #e4e5e7;

$primary: $abq-green;

$abq-font-color: #6a6c6f;

$abq-main-header-height: 57px;
$abq-page-header-height: 53px;
